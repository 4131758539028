import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ClassesPage = () => (
  <Layout>
    <Seo title="Shield Systems Academy Classes" />
    <h1>Classes</h1>
  </Layout>
);

export default ClassesPage;
